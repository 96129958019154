/* 140519 */

/* Global css */

h3 {
  font-size: 2.4rem;
}

h6.fw-bold.primary-color label.demafont {
  font-size: 20px;
}

h6.fw-bold.primary-color {
  font-size: 18px;
  line-height: 25px;
}

.step-footer.ps-3.ps-md-0.pt-0.pt-md-1.pt-lg-5 {
  padding-top: 30px !important;
}

.step-header.d-none.d-md-block.pt-4.pt-md-5 {
  padding-top: 10px !important;
}

.step-header.d-none.d-md-block.mb-2 {
  margin-bottom: 10px !important;
}

.magic-work .leftpart {
  padding-right: 50px;
  width:55%;
}

.magic-work .leftpart .sellerpicvideo {
  width: 100%;
}

.experience-block ul {
  margin-top: 20px;
}

.experience-block ul li {
  width: 40px;
  height: 40px;
  display: inline-block;
  text-align: center;
  line-height: 35px;
  border-radius: 50%;
  background: #fff;
  position: relative;
  border: solid 1px #ddd;
  margin-right: 20px;
}

.magic-work .leftpart img {
  width: 100%;
}

.section-header p {
  font-size: 24px;
  line-height: 33px
}

.section-header .demafont small,
.section-header .demafont {
  font-size:30px;
}

.section-header .demafont {
  color: #7FDBB1;
}

.section-header .demafont small {
  display: inline-block;
  color: #2E3964;
}

.friendly-market-content p label.demafont small {
  font-family: 'demaregular';
  font-size: 18px;
  color: #2E3964;
  display: inline-block;

}

.friendly-market-content h3 label.demafont small {
  color: #2E3964;
  display: inline-block;
  font-size: 35px;
}

h6.fw-bold.primary-color small {
  font-family: 'demaregular';
  font-size: 20px;
  color: #2E3964;
  transform: rotate(-60deg);
  display: inline-block;
  margin-right: 2px;
}

.direction.shopperspageprocess {
  display: flex;
  justify-content: space-between;
  flex-flow: wrap;
  gap: 50px;
}

.sellerbox-left {
  float: left;
  width: 48%;
  text-align: center;
}

.sellerbox-right {
  float: right;
  text-align: center;
  width: 47.5%;
  margin-top: 230px;
}

.sellerpic img,
.sellerbox-right img,
.sellerbox-left img {
  height: 300px;
}

.sellerbox-left.mt-150 {
  margin-top: -150px;
}

.sellerbox-left.mt-300 {
  margin-top: -300px;
}

.sellerbox-left.mt-250 {
  margin-top: -180px;
}

.sellerbox-right.mt-90 {
  margin-top: 40px;
}

.friendly-market-content h3 .demafont{font-size:36px;}
.friendly-market-content h3{
  font-size:33px;
  line-height: 46px;
  margin: 30px 0 10px 0;
}

.section-header.d-inline-block.text-end {
  margin-bottom: 50px;
}

.friendly-market-content h3 {
  margin: 0 0 10px 0;
}

.direction {
  margin-bottom: 80px;
}

.sellerpic.rightpic img {
  float: right;
  padding-right: 50px;
}

.friendly-market-content .readmore:hover img,
.shopperspageprocess .readmore:hover img {
  transition: all 0.5s;
  margin-left: 15px;
}

.shopperspageprocess .friendly-market-content {
  padding-right: 0;
}

.friendly-market-content {
  padding-right: 50px;
  float: left;
}

.seller-experience .section-header {
  margin-bottom: 50px;
}

.seller-experience .section-header p {
  margin-top: 30px;
  font-size: 16px;
}

.experience-block h5 {
  font-size: 18px;
  line-height: 25px;
}

.fullview {
  position: relative;
  z-index: 1;
}

.count-blocks span {
  font-weight: 700;
  font-size: 45px;
  line-height: 61px;
  color: #7FDBB1;
  margin-bottom: 10px;
  display: block;
}

.seller-experience .section-header p.signupgrt {
  font-size: 20px;
  line-height: 27px;
}

.Integratetop {
  padding-bottom: 60px;
}
.boostyourprofits {
  justify-content: space-between;
  gap: 50px;
}
.boostyourprofits .col-md-8{width:61%;}

.boostyourprofits .col-md-4 p {
  font-size: 20px;
  line-height: 30px;
  margin-top: 20px;
}
.boostyourprofits .title1 label.demafont {
  color: #7FDBB1;
  display:inline-block;
}

.boostyourprofits .title1 span {
  display: inline-block;
}


.sellerpicdetails {
  width: 45%;
  padding-right: 50px;
}

.sellerpicdetails .title1 span {
  display: inline-block;
}

.sellerpicdetails .title1 label.demafont {
  color: #7FDBB1;
}

.joining-block {
  margin-top: 40px;
}

.joining-icon {
  width: 60px;
}

.joining-content h5 {
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin-bottom: 10px;
}

.joining-content h5 .demafont{
  font-size:22px;
}

.sellerpicvideo {
  width: 55%;
}

.sellerpicvideo video {
  width: 100%;
  border: solid 1px #ddd;
  border-radius: 20px;
}

.primary-color {
  color: #2E3964;
}

.primary-bg {
  background-color: #2E3964;
}

.secondary-bg {
  background: #7FDBB1
}

.primary-text {
  color: #333333;
}

.secondary-color {
  color: #7FDBB1;
  ;
}
/* count section css */
section.count .col-md-4 {
  border-right: 1px solid #D1D1D1;
}

.count-row {
  padding: 20px;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  position: relative;
}

section.count .col-md-4:last-child {
  border-right: none;
}

.count-row:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1.2px;
  border-radius: 22px;
  background-image: linear-gradient(0deg, rgba(127, 219, 177, 1) 0%, rgb(127 219 177 / 42%) 50%, rgb(237 237 237 / 5%) 100%);
}

.friendly-market-content p {
  margin-bottom: 10px;
}

section.friendly-market {
  position: relative;
  background: url("images/bg-2.svg") no-repeat center bottom;
  background-repeat: no-repeat;
  padding-bottom: 200px !important;
  background-size: cover;
}
.shopperspagbottem{margin-bottom:0;}

/* curvebottem */

/* seller experiencet section css */

.seller-experience .row {
  gap: 50px;
  justify-content: space-between;
}

.experience-block p {
  font-size: 14px;
  font-style: italic;
}

.experience-block {
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.07);
  border-radius: 20px;
  width: 30.3%;
  padding: 30px;
  position: relative;
  background: #fff;
}

.experience-block:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1.2px;
  border-radius: 22px;
  background-image: linear-gradient(0deg, rgba(127, 219, 177, 1) 0%, rgb(127 219 177 / 42%) 50%, rgb(237 237 237 / 5%) 100%);
}

/* boost section css */

section.boost:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 50%;
  height: 350px;
  background-image: url("images/boost-bg.png");
  background-repeat: no-repeat;
  background-size: contain;
  z-index: -1;
}

/* contact form css */

.required {
  border: none;
  border-bottom: 1px solid #A5A5A5;
  width: 100%;
  padding: 10px 0;
  background-color: #fff;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.mc-field-group.input-group ul {
  display: flex;
  width: 100%;
  margin-top: 15px;
 justify-content: space-between;
}
.mc-field-group.input-group ul li {
  display: flex;
  align-items: center;
}

.mc-field-group.fullwidth,
.mc-field-group.input-group.input-group {
  width: 100%;
  margin-right: 0;
}
.mc-field-group.input-group ul li input {
  margin-right: 5px;
}

.mc-field-group.input-group ul li label {
  font-size: 16px;
  line-height: 22px;
  top: auto;
  position: relative;
  left: auto;
  right: auto;
}

.mc-field-group.input-group ul li input:focus~label {
  font-size: 16px;
  top: 0;
  position: relative;
  left: 0;
}

.mc-field-group:nth-child(2n) {
  margin-right: 0;
}

.mc-field-group.marginset {
  margin-right: 0;
  margin-left: 30px;
}

.mc-field-group {
  position: relative;
  width: 47.8%;
  float: left;
  margin-right: 30px;
  margin-bottom: 30px;
}
.shoppersform .mc-field-group {
  margin-bottom:20px;
}
.mc-field-group.fullwidth {
  margin-bottom: 10px;
}
.mc-field-group.fullwidth p {
  padding-top: 10px;
}

.required:focus-visible {
  outline: none;
}
.graycolor{color:#9E9E9E;}
.mc-field-group label {
  position: absolute;
  top: 17px;
  left: 0;
  cursor: text;
  transition: all .15s ease-in-out 0s;

}

.mc-field-group input:focus~label,
.mc-field-group input:valid~label {
  top:-10px;
  font-size: 12px;
  color: #2E3964;
}


.required .bar:before,
.required .bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 0px;
  position: absolute;
  background: #2E3964;
  transition: 0.4s ease all;
  -moz-transition: 0.4s ease all;
  -webkit-transition: 0.4s ease all;
}

.signupleft {
  float: left;
  width: 50%;
  padding-right: 30px;
  margin-bottom: 30px;
}

.signupright {
  width: 50%;
  float: right;
  margin-bottom: 30px;
}

.signupleft .mc-field-group {
  margin-bottom: 20px;
}

.signupleft .mc-field-group,
.signupright .mc-field-group {
  width: 100%;
  margin-right: 0;
}

.boost .bar:before {
  left: 50%;
}

.boost .bar:after {
  right: 50%;
}

.required input:focus~.bar:before,
.required input:focus~.bar:after {
  width: 50%;
}

.required .highlight {
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.boost input:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #2E3964;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #2E3964;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #2E3964;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.boost .text-group textarea:focus~.input-label {
  color: #2E3964;
}

.boost .text-group textarea:focus~.bar::before {
  width: 100%;
  left: 0;
}

.boost input[type='radio'] {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #888;
  border-radius: 50%;
  outline: none;
  position:relative;

}

.boost input[type='radio']:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: auto;
  border-radius: 50%;
  position: absolute;
  left: 1.6px;
  top: 1.3px;
}

.boost input[type='radio']:checked:before {
  background: #7FDBB1;
}

.boost .btn {
  float: right;
  margin-top:20px;
}
.massigessucc {
  font-size: 14px;
  background: #D4F5E6;
  border-radius: 20px;
  padding: 10px 15px;
  position: absolute;
  bottom: 28px;
  text-align: center;
}

.boost form {
  display: inline-block;
  box-shadow: 0px 35px 50px rgba(0, 0, 0, 0.07);
  padding: 30px;
  border-radius: 20px;
  background: #fff;
  position: relative;
}

.boost form:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  margin: -1.2px;
  border-radius: 22px;
  background-image: linear-gradient(0deg, rgba(127, 219, 177, 1) 0%, rgb(127 219 177 / 42%) 50%, rgb(237 237 237 / 5%) 100%);
}

.indicates-required {
  margin-top: -40px;
}

.group {
  margin-right: 30px;
}

.group.mr-0 {
  margin-right: 0;
}

.group:nth-child(2) {
  margin-right: 0;
}

.shopforme {
  margin-top: 30px;
}

.shopforme .btn {
  border: none;
  padding: 10px 40px;
  box-shadow: 0px 25px 25px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.shopforme .btn:focus-visible {
  outline: none;
}

.boost button.meet {
  font-size: 13px;
  border: none;
}

p.primary-text.fs-6 {
  margin-bottom: 15px;
}

/* boost steps css */

section.boost .step {
  width: 20%;
}

.boost .step.green-circle .step-body:before {
  content: '';
  position: absolute;
  bottom: -102px;
  left: 51%;
  background-image: url("images/green-strip.png");
  height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  transform: translate(-50%, 0);
}

.boost .step.green-circle .step-body:after {
  content: '';
  position: absolute;
  bottom: -18%;
  left: 101%;
  background-image: url(images/dashed.png);
  height: 40%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 3px;
  transform: rotate(-45deg);
  z-index: 9;
}

.boost .step.green-circle:last-child .step-body:after {
  display: none;
}

.boost .step.orange-circle .step-body:before {
  content: '';
  position: absolute;
  top: -95px;
  left: 50%;
  background-image: url("images/orange-strip.png");
  height: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  transform: translate(-50%, 0);
}

.boost .step.orange-circle .step-body:after {
  content: '';
  position: absolute;
  top: -19%;
  left: 100%;
  background-image: url(images/dashed.png);
  height: 40%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 4px;
  transform: rotate(-135deg);
  z-index: 9;
}

.boost .step p {
  font-size: 14px;
}

p.msg {
  background: #D4F5E6;
  border-radius: 20px;
}

.magicworkright{width:45%;}
.magic-work {
  position: relative;
  z-index: 1;
}

.magic-work .title1 {
  margin-bottom: 50px;
}

.section-header .title1 span,
.magic-work .title1 span {
  display: inline-block;
}

.magic-work .row {
  align-items: center;
  justify-content: space-between;
}

.magic-work .item {
  font-size: 14px;
}

/* magic work vertical slider */

.force-overflow .item {
  margin-bottom: 50px;
}

.force-overflow .item:last-child {
  margin-bottom: 0;
}

img.live {
  bottom: -90px;
  right: 0;
}

h6.fw-bold.primary-color small,
.magic-work .item p,
.item h6.fw-bold.primary-color {
  color: #D1D1D1;
}

.item.itemactive h6.fw-bold.primary-color small,
.magic-work .item.itemactive p,
.item.itemactive h6.fw-bold.primary-color {
  color: #2E3964;
}

.magic-work .item {
  font-size: 14px;
  padding-left: 30px;
  position: relative;
}

.force-overflow {
  position: relative;
}

.item.itemactive::after {
  content: "";
  position: absolute;
  background: #7FDBB1;
  height: 100%;
  width: 3px;
  top: 0;
  left: -1px;
  animation: spinstop 26s infinite;
  animation-delay: 4s;
}

.force-overflow::before {
  content: "";
  position: absolute;
  background: #D1D1D1;
  height: 100%;
  width: 1px;
  top: 0;
}