.investorpitchibanner{ width: 100%;
    overflow: hidden;
    position: relative;
    padding-top: 10px;
    padding-left: calc((100% - 1200px)/2);
}

.investorpitchi-main{
justify-content: space-between;
position: relative;
}
.investorpitchileft{width:480px;padding-top:60px;}
.investorpitchileft .title1 .demafont{color:#7FDBB1;}
.investorpitchileft .title2{font-weight:400; margin:12px 0 20px 0;}
.investorpitchibtn{transition: all 0.5s;background: none;border: 1px solid #2E3964; float:right;}
.btn.investorpitchibtn img{margin-left:10px;transition:all 0.5s;}
.btn.investorpitchibtn:hover img{display:none;}
.btn.investorpitchibtn:hover img.whitearrow{display:inline-block;transition: all 0.5s;}
.btn.investorpitchibtn:hover{background:#2E3964;transition:all 0.5s; color:#fff;}

.investorpitchi-main .point2{top: 32%;left: 190px;}
.investorpitchi-main .point3{top: 38%;right: 49%; z-index: 1;}
.investorpitchi-main .point4{top: 70px;right:62%;}

/* .investorpitchiright{
    position: relative;
    width:50%;
    min-height: 650px;
    padding-right: calc((100% - 1200px)/2);
} */
/* .investorpitchi-main:before {
  content: "";
  position: absolute;
  right:0;
  width:52%;
  height: 100%;
  background: url(/public/images/banner-pic.svg) 0 -6px no-repeat;
  background-size:cover;
} */
.investorpitchi-main .banner-pic{ padding-right: calc((100% - 1200px)/2);width:60%; justify-content:flex-start;}
.investorpitchicount {
    background: #D3F3E4;
    box-shadow: 0px 35px 50px rgb(0 0 0 / 7%);
    border-radius: 20px;
    position: relative;
    padding: 20px;
    width: 97%;
    top: -210px;
    left: 30px;
}
.investorbox{text-align: center;width: 100%;border-right: 1px solid #008CE2;}
.investorbox strong{font-weight: 700;
    font-size: 45px;
    line-height: 61px;
    text-align: center;
    color: #2E3964;
    display: block;
    margin-bottom: 10px;}
    
    .investorbox p {
        line-height: 22px;
    }
    .investorbox.investorbox02{border-right:none;}
    
    .investorpitchifullvideo {
        width: 100%;overflow:hidden;
        margin-top:-438px;position:relative;
        text-align:center;
    }
    /* .investorpitchifullvideo video{width:100%;height:630px;} */
    .investorpitchifullvideo iframe {
        height:80vh;
        width: 100%;
        border-radius: 20px;
        
    }

    @media screen and (max-width:1350px) and (min-width:1199px) {
        .investorpitchileft{width:610px; padding-left:20px;}
        .investorpitchi-main .banner-pic{padding-right:20px;}
        

    }

    @media screen and (max-width:1199px) and (min-width:1025px) {
        .investorpitchibanner{padding-top:60px;}
        .investorpitchileft{width:620px; padding:0 20px 0 20px;}
        .investorpitchicount{top:-160px;left: 15px;}
        .investorbox strong{font-size:38px; line-height:40px;}
        .investorbox p{font-size:13px;}
        .investorpitchi-main .banner-pic{padding-right:20px;}
        .investorpitchifullvideo{margin-top:-340px;}
    }

    @media screen and (max-width:1024px) and (min-width:993px) {
        .investorpitchibanner{padding-top:60px;}
        .investorpitchileft{width:642px; padding:0 20px 20px 20px;}
        .investorpitchicount{top: -150px;left: 15px;}
        .investorbox strong{font-size:30px; line-height:40px;}
        .investorbox p{font-size:13px;}
        .investorpitchi-main .banner-pic{padding-right:20px;}
        .investorpitchifullvideo{margin-top:-340px;}   
        }

    @media screen and (max-width:992px) and (min-width:769px) {
        .investorpitchibanner{padding-top:60px;}
        .investorpitchileft .title2{font-size:20px; line-height:27px;}
        .investorpitchileft{width:100%; padding:0 20px 20px 20px; text-align:center;}
        .investorpitchibtn{margin:0 auto; float:none; display:inline-block;}
       
        .investorpitchicount{margin-top: 0;flex-direction: row; width:93%;}
        .investorpitchi-main .banner-pic {
            padding-right:30px;
            width: 100%;
        }
        .investorpitchifullvideo{margin-top:-500px;}
    
    
        }

    @media screen and (max-width:768px) and (min-width:320px) {
    .investorpitchibanner{padding-top:60px;padding-bottom:0;}
    .investorpitchileft .title2{font-size:20px; line-height:27px;}
    .investorpitchileft{width:100%; padding:0 20px 20px 20px; text-align:center;}
    .investorpitchibtn{margin:0 auto; float:none; display:inline-block;}
    
    .investorpitchicount{margin-top: 0;
        left: 0;
        top: 0;}
    .investorbox{border-right:none; border-bottom:1px solid #D1D1D1;padding-bottom:20px; margin-bottom:20px;text-align: left;
         display: flex;
        align-items: center;
    }
    .investorbox.investorbox02{padding-bottom:0;margin-bottom:0;border-bottom:none;}
    
    .investorpitchi-main .banner-pic {
        padding-right:30px;
        width: 100%;
    }

      .investorbox strong{margin-right:10px; margin-bottom:0; display:inline-block;font-size:32px;line-height: 38px;}
      .investorbox p{display:inline-block;}

    .investorpitchifullvideo{margin-top:30px;}
    .investorpitchifullvideo iframe{height:40vh;}        

    }

    @media screen and (max-width:360px) {
        .investorpitchileft .title1{font-size:25px;line-height:30px}
        .investorpitchileft .title2{font-size:18px;}

    }
