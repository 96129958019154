@media only screen and (max-width: 1199px) {
    section.banner-sec.buyer {
        background-size: 50%;
    }
    .scrollbar {
        height: 235px;
    }
}
@media screen and (max-width:1224px) and (min-width:993px){
.seller-experience .row{gap:40px;}
section.boost:before{width:100%;}
.sellerpicdetails .title1,
.magic-work .title1{font-size:34px; line-height:45px; margin-bottom:20px;}
.magic-work .item{margin-bottom:20px;}
.direction.shopperspageprocess{gap:40px;}
.joining-block{margin-top:20px;}
.mc-field-group{width:47%;}
.sellerpic img,
.sellerbox-right img, .sellerbox-left img {
    max-height: 250px;
}
.signupright{width:49%;margin-bottom:0;}
.mc-field-group.input-group ul li{padding-right:10px;}
.mc-field-group.input-group ul li label{font-size:12px;}
.boost .step.green-circle .step-body:before{bottom: -78px}
.boost .step.orange-circle .step-body:before{top:-80px;}
.step-body.pb-5.pb-md-0.mt-0.mt-md-5.position-relative{margin-top:20px!important;}
.boost .step.green-circle .step-body:after{transform: rotate(-53deg);left:111%;bottom:-5%;}
.mc-field-group.input-group ul{flex-flow: wrap;}
.massigessucc {
    font-size: 11px;
    padding: 10px;
    bottom: 25px;
    width: 73%;
 }
}


@media screen and (max-width:992px) and (min-width:769px){
    .boost .step.green-circle .step-body:before {
        bottom: -68px;
    }
    .boost .step.orange-circle .step-body:before {
        top:-68px;
    }
    h2, h3 {
        font-size: 30px;
    }
    section.banner-sec {
        background-size: 50%;
        min-height: 350px;
    }
    section.banner-sec:after {
        width: 250px;
        height: 250px;
        z-index: -1;
    }

    .sellerpicvideo{margin-bottom:20px;}
    .sellerpicvideo,
    .sellerpicdetails{width:100%; padding:0;}
    .sellerpicvideo img{width:100%;}
    .joining-block {margin-top:20px;flex-direction: row;}   
    .sellerpic img{gap: 30px;height:200px;}
    .seller-experience .row{ flex-direction:column;gap:30px;}
    .experience-block{width:100%;padding: 20px;}
    .experience-block .d-flex.align-items-center.mb-4{flex-direction: row;}
    .seller-experience .section-header p.signupgrt{font-size:14px;}
    .boost form{padding:20px;}
    .me-2{margin-right:5px!important;}
    .boost form .d-flex.align-items-center.mb-4 {
        flex-direction: row;
    }
    section.boost:before{width:100%;}    
    img.live{bottom:-71px;}     
.boost .title1 ,    
.boostyourprofits .title1{font-size:40px; line-height:50px;}
.boostyourprofits .title1 label.demafont small,
.boostyourprofits .title1 label.demafont{font-size:45px; line-height:50px;}
.direction .readmore{float:left;}
.direction:nth-child(3), .direction:nth-child(5), .direction:nth-child(7), .direction:nth-child(9) {
    flex-direction:row !important;
}
.boostyourprofits .col-md-4 p {
    font-size: 16px;
    line-height: 20px;
    margin-top:10px;
}
.step-body.pb-5.pb-md-0.mt-0.mt-md-5.position-relative{margin-top:15px!important;}
.joining .row.align-items-center.justify-content-between{flex-direction: column-reverse;}
.friendly-market-content h3 .demafont{font-size:29px;}
.friendly-market-content h3{
    font-size:25px;line-height:35px;}
section.friendly-market{padding-bottom:100px !important;}
.shopperspagbottem{margin-bottom:100px;}

p.my-3.my-md-4.fst-italic.pe-md-5.me-md-5{padding-right:0 !important;}
.magic-work .leftpart {
    padding: 0;
    width: 100%;
}
h2.title1.d-block.d-md-none{display:block !important;margin-bottom:30px;}
h2.title1.mobilehidetitlemagic{display:none;}
.scrollbar {margin-top:30px;}
.magic-work .col-md-6{width:100%;}
.direction.shopperspageprocess{gap:20px;}
.seller-experience .section-header p{margin-top:10px;}
.message{flex-flow:row;}
p.msg{font-size:14px;}
.sellerpicvideo video{height: 52vw;}
.mc-field-group{width:46.5%;}
.signupright{width:48%;margin-bottom:0;}
.signupleft{margin-bottom:35px; padding-right: 10px;}
.boostyourprofits{gap:20px;}
.boostyourprofits .col-md-8{width:63%;}
.mc-field-group.input-group ul{flex-flow:wrap;}
.mc-field-group{margin-bottom:20px;}
.massigessucc {
    font-size: 11px;
    padding: 7px;
    bottom: 18px;
    width: 63%;
    line-height: 14px;
}

}

@media screen and (max-width:768px){
    section.banner-sec:after {
        display: none;
    }
    .content {
        text-align: center;
    }
    section.banner-sec h1 {
        font-size: 30px !important;
    }
    section.banner-sec {
        background-image: none;
    }
    section.joining .row, section.friendly-market .row:nth-child(even) {
        flex-direction: column-reverse;
    }
    .section-header {
        text-align: center !important;
    }
    section.friendly-market .section-header h2 {
        font-size: 30px;
    }

    .friendly-market-content h3 label.demafont small,
    .friendly-market-content h3 {
        font-size: 20px;
        line-height: 27px;
    }
    section.boost .step {
        width: 100%;
        flex-direction: row;
    }
    .boost .step.green-circle .step-body:before, .boost .step.orange-circle .step-body:before {
        display: none;
    }
    section.boost .step:nth-child(even) {
        flex-direction: row-reverse;
        justify-content: left;
    }
    .step-footer {
        padding-left:10px !important;
        text-align: left;
        padding-bottom:0;
        margin-top: -40px;
    }
    .form-area .col-md-6{width:100%;}
    .group{margin-right:0;}
    .boost .step.green-circle .step-body:after {
        bottom: 0;
        left: 52%;
        height: 27%;
        width: 2px;
        transform: rotate(0);
    }
    .boost .step.orange-circle .step-body:after {
        bottom:0px;
        top: auto;
        left: 50%;
        height: 30%;
        width:2px;
        transform: rotate(0);
    }
    .step-footer h6 {
        font-size: 21px;
    }
    .step-body img {
        width:120px;
      }
      .joining-content h5 .demafont small{font-size:18px;}
    .boost button.meet {
        width:auto;
    }
    section.count .col-md-4{
        border-right: none;width:100%;
        border-bottom: 1px solid #D1D1D1;
    }
    .count-blocks span{font-size:35px; margin-right:10px;}
    .count-blocks {
        display: flex;
        align-items: center;
    }
    section.count .col-md-4:last-child {
        border-bottom: none;
    }
    section.banner-sec.buyer {
        background-image: none;
    }
    img.live {
        bottom: 135px;
        right: -170px;
        width: 34%;
        display:none;
    }

    .section-header .title1 {
        width: 250px;
        margin: 0 auto;
    }
    .boost input[type='radio']:before{top:1.5px;}
    section.friendly-market {
        padding-bottom:60px !important;
        background-size: contain;
    }
    .direction{margin-bottom:60px;flex-direction: row-reverse !important;}
    .direction .col-md-6{width:100%;}
    .col-md-6.sellerpic img,
    .sellerpic.rightpic img{padding-right:0; float:none;}
    section.boost:before{display:none;}
    .group.form-group.position-relative.mb-4.mb-md-0{margin-bottom: 30px!important;;}
    .friendly-market-content{padding:0; margin-top:20px; text-align:center;}
    .friendly-market-content .readmore{float:none;}
    .direction:nth-child(3), .direction:nth-child(5), .direction:nth-child(7), .direction:nth-child(9) {
        flex-direction: column-reverse !important;
    }
    section.count p {
        font-size: 14px;
    }
    .joining-content h5 .demafont{font-size:18px;}
    .joining-block h5{font-size: 16px;}
    .joining-content span{display: block;}
    section.friendly-market.buyer {
        padding-bottom: 40px !important;
    }
    section.banner-sec p span {
        text-align: center !important;
    }
    .message p {
        font-size: 12px;
        order: 2;
        margin-top: 20px;
    }
    .message button {
        order: 1;
    }
    .message {
        justify-content: flex-end !important;
    }
    .sellerpicvideo video{height:100%;}    
    .sellerpicvideo{margin-bottom:20px;}
    .sellerpicvideo,
    .sellerpicdetails{width:100%; padding:0;}
    .sellerpicvideo img{width:100%;}
    .joining-block {margin-top:20px;flex-direction: row;}   
    .section-header p{font-size:16px;}
    .section-header .demafont small, .section-header .demafont{font-size:21px;}
    .sellerpic img{gap: 30px;}
    .seller-experience .row{ flex-direction:column;}
    .experience-block{width:100%;padding: 20px;}
    .experience-block .d-flex.align-items-center.mb-4{flex-direction: row;}
    .seller-experience .section-header p.signupgrt{font-size:14px;}
    .boost form{padding:20px;}
    .me-2{margin-right:5px!important;}
    .boostyourprofits{gap:0;}
    .boost form .d-flex.align-items-center.mb-4 {
        flex-direction: row;}
    .sellerpic{text-align:center; margin:auto;}


    .bannerdetails .text-end {text-align:center !important;}
    .magic-work .leftpart{padding:0; width:100%;}
.mobilehidetitlemagic{display:none;}
.magic-work .title1{margin-bottom:30px;}
.scrollbar{margin-top:30px;}
.force-overflow .item{margin-bottom:20px;}
h6.fw-bold.primary-color small,
h6.fw-bold.primary-color{font-size:16px;}
.magicworkright,
.sellerbox-right,
.sellerbox-left{width:100%; margin-top:0;}
.friendly-market-content h3 .demafont{font-size:28px;}
.friendly-market-content h3, .shopperspageprocess .friendly-market-content h3{
line-height:35px;font-size:25px;
}
.sellerbox-left.mt-250,
.sellerbox-right.mt-90,
.sellerbox-left.mt-300,
.sellerbox-left.mt-150{margin-top:0;}
.seller-experience .section-header p{margin-top:10px;}
section.boost.pt.position-relative .col-md-12,
section.boost.pt.position-relative .col-md-4,
section.boost.pt.position-relative .col-md-8{width:100%;}
.shopforme{margin-top:0;}
h2.title1.d-block.d-md-none {
    display: block !important;
    width: 280px;
    margin: auto auto 30px auto;
    text-align: center;
}
.direction.shopperspageprocess{gap:30px;}
.mc-field-group{width:100%;}
.mc-field-group.marginset{margin-left:0!important;}
.sellerpic img,
.sellerbox-right img, .sellerbox-left img {
    height:140px;
}
.signupright,
.signupleft{width:100%; margin-bottom:0; padding-right:0;}
.boostyourprofits .col-md-4 p{font-size: 18px;line-height: 25px;margin-top: 10px;}
.mc-field-group.input-group ul{flex-direction:column;margin-top:0;}
.mc-field-group.input-group ul li{padding-top:10px;}
.mc-field-group{margin-bottom:20px;}
.massigessucc {
    font-size: 12px;
    padding: 7px;
    width: 100%;
    line-height: 18px;
    margin-top: 50px;
    position: relative;
    display: inline-block;
}
.boostyourprofits .col-md-8{width:100%;}

}